import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../atoms/button/button';
import { Text } from '../../atoms/text/text';
import Modal from '../../molecules/modal/modal';
import { Organization } from '@models/organization';
import { Heading } from '@components/atoms/heading/heading';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import { formatAddress } from '@hooks/use-organization';

interface Props {
  isOpen: boolean;
  close: () => void;
  organization?: Organization;
}

export const ChurchTreasurerRegistrationModal = ({
  isOpen,
  close,
  organization,
  ...props
}: Props) => {
  const intl = useIntl();
  const isCanadianOrg = organization?.country === 'Canada';
  return (
    <Modal
      size="S"
      isOpen={isOpen}
      onClose={() => close()}
      {...props}
      data-testid="treasurer-registration-modal"
    >
      <div className="container-sm text-center">
        <Heading variant="h3" color="nad-blue">
          <FormattedMessage
            id="jq13Ji"
            defaultMessage="Church Treasurer Registration Process"
            description="Title on church treasurer registration process modal."
          />
        </Heading>
        <Heading variant="h4" color="nad-blue" className="mt-3">
          {organization?.name}
        </Heading>
        <HeadingUppercase variant="h6" color="nad-blue" className="mt-1">
          {organization && formatAddress(organization)}
        </HeadingUppercase>

        <div className="text-left md:px-20 space-y-6">
          <Text size="XS" color="nad-alps-night" className="mt-6 font-sans">
            <FormattedMessage
              id="DW8UKb"
              defaultMessage="To register as an official church treasurer within AdventistGiving, please follow the instructions below:"
              description="Description on church treasurer registration process modal."
            />
          </Text>
          <ol className="text-xs text-nad-alps-night list-decimal ml-4">
            <li>
              <FormattedMessage
                id="oGePDg"
                defaultMessage="<a>Download</a> and fill out the PDF form."
                description="List item in churhch treasurer registration process."
                values={{
                  a: (text) => (
                    <a
                      href={
                        isCanadianOrg
                          ? '/noprecache/assets/AdventistGivingChurchEnrollment_Canada.pdf'
                          : '/noprecache/assets/AdventistGivingChurchEnrollment.pdf'
                      }
                      className="underline"
                    >
                      {text}
                    </a>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                id="KSeAHx"
                defaultMessage="Have the form signed by your Pastor & Conference Treasurer."
                description="List item in churhch treasurer registration process."
              />
            </li>
            <li>
              <FormattedMessage
                id="Ezqu83"
                defaultMessage="Submit the signed form via Email or Fax."
                description="List item in churhch treasurer registration process."
              />
              <ol className="list-[lower-alpha] ml-4">
                <li>
                  <FormattedMessage
                    id="1f+s+C"
                    defaultMessage="Email: <a>Help@AdventistGiving.org</a>"
                    description="List item in churhch treasurer registration process."
                    values={{
                      a: (text) => (
                        <a
                          className="underline"
                          href="mailto:Help@AdventistGiving.org"
                        >
                          {text}
                        </a>
                      ),
                    }}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="pNmfGd"
                    defaultMessage="Fax: 866-424-0956"
                    description="List item in churhch treasurer registration process."
                  />
                </li>
              </ol>
            </li>
            <li>
              <FormattedMessage
                id="Ns8PML"
                defaultMessage="You will receive an email once your registration has been approved."
                description="List item in churhch treasurer registration process."
              />
            </li>
          </ol>
        </div>
        <Button width="large" className="mt-8" onClick={() => close()}>
          <FormattedMessage
            id="jkVqGr"
            defaultMessage="Done"
            description="Button to close modal."
          />
        </Button>
      </div>
    </Modal>
  );
};
