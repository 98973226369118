import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateEmail,
  validateRequiredFields,
} from './use-form-validation';
import { setOfflineErrors, useOnline } from './use-online';
import { fetchPost } from '@fetch/helpers';

export interface ChurchEnrollmentFormData {
  requester_name: string;
  requester_email: string;
  treasurer_name: string;
  treasurer_email: string;
}

interface ValidationParams {
  requiredFields: (keyof ChurchEnrollmentFormData)[];
  values: ChurchEnrollmentFormData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const requester_email = validateEmail(values.requester_email);
  if (requester_email) {
    errors.requester_email = requester_email;
  }
  const treasurer_email = validateEmail(values.treasurer_email);
  if (treasurer_email) {
    errors.treasurer_email = treasurer_email;
  }
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

const useChurchEnrollmentForm = (orgId?: string, onSuccess?: () => void) => {
  const requiredFields: (keyof ChurchEnrollmentFormData)[] = useMemo(() => {
    return [
      'requester_name',
      'requester_email',
      'treasurer_name',
      'treasurer_email',
    ];
  }, []);
  const formData: ChurchEnrollmentFormData = {
    requester_name: '',
    requester_email: '',
    treasurer_name: '',
    treasurer_email: '',
  };
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<ChurchEnrollmentFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intl = useIntl();
  const isOnline = useOnline();

  const reset = () => {
    setValues(formData);
    setErrors(undefined);
    setFormHasErrors(false);
    setSubmitError(undefined);
    setHasSubmitted(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, values]);

  const handleChange = handleFormChange({
    values,
    setValues,
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    if (setOfflineErrors({ intl, isOnline, setIsSubmitting, setSubmitError })) {
      return;
    }

    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      return;
    }

    try {
      const response = await fetchPost({
        url: `/api/v3/organizations/${orgId}/enrollment_interests`,
        data: values,
      });
      if (response.ok) {
        onSuccess && onSuccess();
        reset();
      } else {
        setIsSubmitting(false);
        setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      }
    } catch (err) {
      setIsSubmitting(false);
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
    }
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    reset,
  };
};

export default useChurchEnrollmentForm;
