import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { Button } from '../../atoms/button/button';
import InputText from '../../atoms/input-text/input-text';
import { Text } from '../../atoms/text/text';
import Modal from '../../molecules/modal/modal';
import { ChurchTreasurerRegistrationModal } from '../church-treasurer-registration-modal/church-treasurer-registration-modal';
import { Organization } from '@models/organization';
import { Heading } from '@components/atoms/heading/heading';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import useChurchEnrollmentForm from '@hooks/use-church-enrollment-form';
import { getElementError } from '@hooks/use-form-validation';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import { formatAddress } from '@hooks/use-organization';
import Sticky from '@components/molecules/sticky/sticky';
import PublicEnv from 'helpers/public-env';

const messages = defineMessages({
  labelYourName: {
    id: 'pTPtAp',
    defaultMessage: 'Your Name',
    description: 'Label for your name on church enrollment',
  },
  labelYourEmail: {
    id: '81vudp',
    defaultMessage: 'Your Email',
    description: 'Label for your email on church enrollment',
  },
  labelTreasurerName: {
    id: 'J5OYZe',
    defaultMessage: 'Church Treasurer Name',
    description: 'Label for church treasurer name on church enrollment',
  },
  labelTreasurerEmail: {
    id: 'YpLoyK',
    defaultMessage: 'Church Treasurer Email',
    description: 'Label for church treasurer email on church enrollment',
  },
  labelNotEnrolled: {
    id: 'EAu/s0',
    defaultMessage: 'This church is currently not enrolled',
    description: 'Label for not enrolled churchs',
  },
});

interface Props {
  isOpen: boolean;
  close: () => void;
  organization?: Organization;
  isNotEnrolled?: boolean;
}

export const ChurchEnrollmentModal = ({
  isOpen,
  close,
  organization,
  isNotEnrolled = false,
  ...props
}: Props) => {
  const intl = useIntl();
  const router = useRouter();
  const [openRegistration, setOpenRegistration] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
    reset,
  } = useChurchEnrollmentForm(organization?.id, () => {
    close();
  });
  const getError = getElementError(errors, hasSubmitted);
  return (
    <>
      <Modal
        size="M"
        isOpen={isOpen}
        onClose={() => {
          reset();
          close();
        }}
        {...props}
      >
        <form onSubmit={handleSubmit} noValidate>
          <div className="container-sm">
            {isNotEnrolled && (
              <Sticky closable={false} className="!p-5 mb-10 ">
                <Text size="XS" color="nad-blue" className="text-center">
                  {intl.formatMessage(messages.labelNotEnrolled)}
                </Text>
              </Sticky>
            )}
            <div className="text-center">
              <Heading variant="h3" color="nad-blue">
                <FormattedMessage
                  id="DiTK7t"
                  defaultMessage="Request Church Enrollment"
                  description="Title on church enrollment modal."
                />
              </Heading>
              <Heading variant="h4" color="nad-blue" className="mt-3">
                {organization?.name}
              </Heading>
              <HeadingUppercase variant="h6" color="nad-blue" className="mt-1">
                {organization && formatAddress(organization)}
              </HeadingUppercase>
            </div>
            <Text
              size="XS"
              color="nad-alps-night"
              className="md:px-8 mt-6 font-sans"
            >
              <FormattedMessage
                id="0xmk3X"
                defaultMessage="The official church treasurer of your church needs to be the one who sets up an AdventistGiving account for your church. You can request that the treasurer of your church begin the process by sending them a request using the form below."
                description="Description on church enrollment modal."
              />
            </Text>
            <Text
              size="XS"
              color="nad-alps-night"
              className="md:px-8 mt-6 mb-4 font-sans"
            >
              <FormattedMessage
                id="Cw3xeb"
                defaultMessage="If you are the treasurer of your church, you can begin the <a>registration process here.</a>"
                description="CTA for treasurers on church enrollment modal."
                values={{
                  a: (text) => (
                    <button
                      type="button"
                      tabIndex={-1}
                      onClick={() => {
                        reset();
                        close();
                        if (
                          PublicEnv.get(
                            'NEXT_PUBLIC_USE_NEW_CHURCH_ENROLLMENT'
                          ) === 'true'
                        ) {
                          router.push({
                            pathname: `/treasurer/${organization?.id}/registration`,
                          });
                        } else {
                          setOpenRegistration(true);
                        }
                      }}
                      className="underline"
                      data-testid="treasurer-registration"
                    >
                      {text}
                    </button>
                  ),
                }}
              />
            </Text>
            <div className="w-full mb-6 md:px-8 space-y-9 pt-6">
              <div className="space-y-6">
                <InputText
                  label={intl.formatMessage(messages.labelYourName)}
                  onChange={handleChange}
                  name="requester_name"
                  value={values.requester_name}
                  error={getError('requester_name')}
                  required
                  autoComplete="given-name"
                />
                <InputText
                  label={intl.formatMessage(messages.labelYourEmail)}
                  onChange={handleChange}
                  name="requester_email"
                  value={values.requester_email}
                  error={getError('requester_email')}
                  required
                  autoComplete="email"
                />
              </div>
              <div className="space-y-6">
                <InputText
                  label={intl.formatMessage(messages.labelTreasurerName)}
                  onChange={handleChange}
                  name="treasurer_name"
                  value={values.treasurer_name}
                  error={getError('treasurer_name')}
                  required
                  autoComplete="given-name"
                />
                <InputText
                  label={intl.formatMessage(messages.labelTreasurerEmail)}
                  onChange={handleChange}
                  name="treasurer_email"
                  value={values.treasurer_email}
                  error={getError('treasurer_email')}
                  required
                  autoComplete="email"
                />
              </div>
              <div className="flex flex-col gap-4 justify-center">
                <FormErrorMessage errors={submitError} />
                <Button
                  isLoading={isSubmitting}
                  disabled={isSubmitting || (hasSubmitted && hasErrors)}
                  width="large"
                  data-testid="submit-enrollment"
                  className="mx-auto"
                  type="submit"
                >
                  <FormattedMessage
                    id="RCelSj"
                    defaultMessage="Send Request"
                    description="Send request button on church enrollment."
                  />
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ChurchTreasurerRegistrationModal
        organization={organization}
        isOpen={openRegistration}
        close={() => setOpenRegistration(false)}
      />
    </>
  );
};
