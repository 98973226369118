import { ReactNode } from 'react';
import { IconClose } from '../../atoms/icons';

interface Props {
  className?: string;
  onClose?(): void;
  closable: boolean;
  children: ReactNode;
}

const Sticky = ({
  children,
  className = '',
  onClose = () => {},
  closable = true,
}: Props) => {
  return (
    <div className={`bg-nad-light-blue p-6 md:p-14 ${className} relative`}>
      {closable && (
        <span
          className="absolute top-6 right-6 cursor-pointer"
          onClick={() => {
            onClose();
          }}
          data-testid="sticky-close"
        >
          <IconClose width={12} height={12} />
        </span>
      )}
      <div className="font-sans space-y-5">{children}</div>
    </div>
  );
};
export default Sticky;
